import React from 'react';
import styled from 'styled-components';

const Text = ({ children }) => (
    <P>{children}</P>
);

export const P = styled.div`
    line-height: 1.7;
`;

export default Text;

