import React from 'react';
import styled from 'styled-components';

const Browser = ({ src }) => (
    <Container>
        <Toolbar>
            <Buttons>
                <Button />
                <Button />
                <Button />
            </Buttons>
        </Toolbar>
        <Image src={src} />
    </Container>
);

const Container = styled.div`
    width: 100%;
    border: 2px solid white;
    margin-bottom: 60px;
    border-radius: 6px;
`;

const Toolbar = styled.div`
    position: relative;
    width: 100%;
    height: 37px;
    border-bottom: 2px solid white;
    display: flex;
    align-items: center;
    padding: 0px 10px;
`;

const Buttons = styled.div`
    display: flex;
`;

const Button = styled.div`
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: white;
    margin-right: 6px;
`;

const Image = styled.img`
    display: block;
    width: 100%;
`;

export default Browser;