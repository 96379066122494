import React from 'react';
import Transition from '@components/Transition';
import Title from '@components/Title';
import Blurb from '@components/Blurb';
import BlurbImage from '@components/BlurbImage';
import Text from '@components/Text';
import Browser from '@components/Browser';
import cityDefenseImg from '@assets/img/city-defense-1.jpg';
import cityDefenseGif from '@assets/img/city-defense.gif';
import { Helmet } from 'react-helmet';

const CityDefense = () => (
    <Transition>
        <Helmet>
            <title>City Defense | Austen Payan</title>
            <link rel="canonical" href="https://austen.io/work/city-defense" />
        </Helmet>
        <Title>City Defense</Title>
        <Blurb
            left={() => (
                <Text>
                    <ul>
                        <li>Browser Game</li>
                        <li>2015</li>
                    </ul>
                </Text>
            )}
            right={() => (
                <>
                    <Text>City defense is a browser game I built in my free time that was created to practice implementing ES6 features (Which was quite new in 2015).</Text>
                    <br />
                    <Text>Using the HTML5 canvas element and the API it exposes, the canvas scene is updated frame-by-frame using requestAnimationFrame, calculating trajectories of meteors and bullets, detecting collisions, and even throwing physics like gravity forces into the mix.</Text>
                </>
            )}
        />
        <Browser
            src={cityDefenseImg}
        />
        <Blurb
            left={() => (
                <Text>How it works</Text>
            )}
            right={() => (
                <>
                    <Text>I got the idea for the game when I was reminded of an old meteor-shooter I used to play on Windows XP. I thought it would be fun to recreate that game dynamic while learning to use some new syntaxes and features of ES2015.</Text>
                    <BlurbImage src={cityDefenseGif} />
                    <Text>Pressing A or D will fire the left and right guns, respectively. The bullet trajectory is based on where the mouse cursor was at the time of firing. Falling red meteors that are intercepted by a bullet will explode in a bright orange ball. If a meteor reaches the city (currently a white strip along the bottom of the frame), that portion of the city will take damage, changing in color until it is completely dark -- destroyed.</Text>
                </>
            )}
            useTopRule={true}
        />
    </Transition>
);

export default CityDefense;