import React from 'react';
import styled from 'styled-components';

const Blurb = ({ left, right, useTopRule, useBottomRule }) => (
    <Container
        useBottomRule={useBottomRule}
        useTopRule={useTopRule}
    >
        <Left>{left ? left() : null}</Left>
        <Right>{right ? right() : null}</Right>
    </Container>
);

const Container = styled.div`
    display: flex;
    padding: 30px 0px 60px 0px;
    ${props => props.useTopRule ? `
        border-top: 2px solid white;
        margin-top: 40px;
    ` : null}
    ${props => props.useBottomRule ? `
        border-bottom: 2px solid white;
        margin-bottom: 40px;
    ` : null}

    @media (max-width: 1000px) {
        display: block;
    }
`;

const Left = styled.div`
    flex: 0 1 50%;
    margin-right: 8px;

    @media (max-width: 1000px) {
        margin-right: 0px;
        margin-bottom: 20px;
    }
`;

const Right = styled.div`
    flex: 0 1 50%;
    margin-left: 8px;
    overflow: hidden;

    @media (max-width: 1000px) {
        margin-left: 0px;
    }
`;

export default Blurb;