import styled from 'styled-components';

export default styled.img`
    width: 100%;
    /* border: 2px solid white; */
    border-radius: 5px;

    &:not(:first-child) {
        margin-top: 40px;
    }

    &:not(:last-child) {
        margin-bottom: 40px;
    }
`;